// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// third party
import Rails from '@rails/ujs';
import '@fortawesome/fontawesome-free/css/all.min.css';

// internal
function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('./admins/', true, /.(js|scss)$/));
importAll(require.context('./css/', true, /.(js|scss)$/));
importAll(require.context('./participants/', true, /.(js|scss)$/));
importAll(require.context('./shared/', true, /.(js|scss)$/));
require.context('../images', true);

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
window.Stimulus = Application.start();
const context = require.context('./controllers', true, /\.js$/);
Stimulus.load(definitionsFromContext(context));
Rails.start();

document.addEventListener('ajax:complete', (e) => {
  const [request, string] = e.detail;
  if (request.status === 401) {
    alert('Your session has expired. Please log in again.');
    window.location.href = '/participants/sign_in';
  }
});
